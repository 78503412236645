import React, { Component } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { ModalActions } from '../../../../stores/ModalStore'

export default class Dropdown extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
		}

		this._openDropdown = this._openDropdown.bind(this)
	}

	_openDropdown(e) {
		e.stopPropagation();
		this.setState({ open: true })
		ModalActions.UpdateParams({ callDropdownOpen: false })

		if(!!this.props.onClickEvent)
		this.props.onClickEvent()
	}

	componentDidUpdate(prevProps, _){
		if(prevProps.closeToggle !== this.props.closeToggle) {
			if(this.props.closeToggle && this.state.open) {

				this.setState({ open: false })
				ModalActions.UpdateParams({ callDropdownOpen: false })
				if(this.props.onOverlayClick) {
					this.props.onOverlayClick()
				}
		
				if(!!this.props.onClickEvent)
				this.props.onClickEvent()
			}
		}
	}

	render() {
		const DropdownItems = this.props.DropdownItems
		const { overlayIndex = 2, containerIndex = 3, callDropdownOpen = false} = this.props
		return (
			<div
				className={this.props.className}
				onClick={(e) => !this.state.open && this._openDropdown(e)}
			>
				{this.props.children}
				{(this.state.open || callDropdownOpen) && (
					<ClosingUnderlay 
						onClick={(e) => {
							e.stopPropagation();
							this.setState({ open: false }); 
							ModalActions.UpdateParams({ callDropdownOpen: false }) 
							if(this.props.onOverlayClick) {
								this.props.onOverlayClick()
							}
						}} overlayIndex={overlayIndex} />
				)}
				{(this.state.open || callDropdownOpen) && (
					<Container className={`topbar rounded inner ${this.props.video ? 'video' : ''} dropdown-content`} left={this.props.left} right={this.props.right} noright={this.props.noright} width={this.props.width} containerIndex={containerIndex}>
						<DropdownItems
							{...this.props}
							onClose={(...args) => {
								this.setState({ open: false })
								ModalActions.UpdateParams({ callDropdownOpen: false })
								this.props.onClose && this.props.onClose(...args)
								// this.props.closeCb && this.props.closeCb(...args)
							}}
						/>
					</Container>
				)}
			</div>
		)
	}
}

const ClosingUnderlay = styled.div`
	position: fixed;
	z-index: ${props => props.overlayIndex};
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`

const Container = styled.div`
	bottom: -6px;
	${(props) => (props.left && 'left: -9px;')};
	${(props) => (props.right && 'right: 10px;')};
	${(props) => (props.noright && 'right: 0px;')};
	padding: 10px;
	width: ${(props) => (props.width || '200px')};
	box-shadow: ${(props) => props.theme.shadows.newheavy};
	transform: translateY(100%);
	position: absolute;
	z-index: ${props => props.containerIndex};

	@media (max-width: 860px) {
		&.video {
			width: 95%;
			margin: 0 auto;
			right: 0;
			left: 0;
		}
	}
`
